import React, { useState, useEffect } from 'react';
import { desialMesialConfig } from './config';
import { unionThemeColorsSet } from '../../../theme';
import { getMiddleValue } from '../../../services/iprService';

const svgFilterByEnv = () => {
  const theme = process.env.REACT_APP_THEME;
  const filters = {
    sts: 'invert(45%) sepia(98%) saturate(507%) hue-rotate(139deg) brightness(98%) contrast(105%)',
    ste: 'invert(51%) sepia(98%) saturate(2618%) hue-rotate(249deg) brightness(89%) contrast(93%)',
  }

  return filters[theme] || filters.ste;
}


const { primaryColor } = unionThemeColorsSet();


export default function Tooth({ img, data, number, next }) {
  const [isActive, setIsActive] = useState(false);
  const [sum, setSum] = useState(null);

  useEffect(() => {
    if (data) {
      const { isAttachment } = data;

      if (isAttachment) {
        setIsActive(true);
      }

      setSum(getMiddleValue(data, next));
    }
  }, []);


  return (
    <div className="teeth">
      <div className="tooth">
        <img
          className='tooth__icon'
          style={{ filter: isActive && svgFilterByEnv() }}
          src={img}
          alt="tooth icon"
        />
        <div className="tooth__number">{desialMesialConfig[number].nr}</div>
        {sum ? (
          <div
            className={`middle-info ${process.env.REACT_APP_THEME}`}
            style={{ color: primaryColor.dark }}
          >
            <div className="mm">
              {sum.mm}
            </div>
            <div className="step">
              Before step: {sum.step}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}
