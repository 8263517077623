import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router";
import { push } from 'react-router-redux';
import { Field, change } from 'redux-form';
import { connect } from 'react-redux';
import { showNotification } from 'react-admin';
import classNames from 'classnames';
import ChatMessage from './ChatMessage';
import ChatApp from './ChatApp';
import MessageMe from "./MessageMe";
import pusher from '../../../config/pusher';
import './index.scss'


function ChatWidget({ record, permissions }) {
  const { chat, id: caseId  } = record;
  const [currentView, setCurrentView] = useState('chatApp');
  const [chats, setChats] = useState([]);
  const [message, setMessage] = useState('');

  const actionsBtnBackgroundColor = (env) => {
    const envHandler = {
      'default': '#84C5E7',
      'sts': '#00b3c5',
      'ste': '#c158dc'
    };
    
    return envHandler[env] || envHandler['default'];
  };

  useEffect(() => {
    const channel = pusher.subscribe(caseId);

    channel.bind('pusher:subscription_succeeded', () => setChats(chat));

    channel.bind('my-event', data => setChats(chats => [...chats, data]));

    return () => channel.unbind();
  }, []);

  useEffect(() => {
    setChats(chat);
  }, [record]);

  let view = '';
  if (currentView === "ChatMessage") {
    view = <ChatMessage changeView={setCurrentView} />
  } else if (currentView === "chatApp") {
    view = <ChatApp
      changeView={setCurrentView}
      chatHistory={chat}
      caseId={caseId}
      role={permissions}
      message={message}
      setMessage={setMessage}
      chats={chats}
      colorChange={actionsBtnBackgroundColor}
    />
  }
  else if (currentView === 'messageMe') {
    view = <MessageMe
      colorChange={actionsBtnBackgroundColor}
      changeView={setCurrentView}
    />
  }

  return (
    <div className={classNames({
      'ChatWidget': true,
      'off': currentView === 'messageMe',
    })}>
      {view}
    </div>
  );
}
export default withRouter(connect(null, {
  showNotification,
  push,
  change
})(({ source, record, permissions }) =>
  <Field
    name={source}
    component={ChatWidget}
    record={record}
    permissions={permissions}
  />));