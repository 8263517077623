import React, { useEffect, useState } from 'react';
import {
  DateField,
  Create,
  SimpleForm,
  LongTextInput,
  CardActions,
  TabbedForm,
  FormTab,
  TextInput,
  SaveButton,
  Toolbar,
  Datagrid,
  ArrayField,
  FunctionField,
  BooleanField,
  TextField as RaTextField,
  DeleteButton,
} from 'react-admin';
import { Edit } from 'ra-ui-materialui/lib/detail';
import { withStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import randomstring from 'randomstring';
import Midline from './Midline/';
import Relationship from './Realtionship/';
import Instructions from './Instructions/';
import TeethGroup from './TeethCheckGroup/';
import StatusButton from './Actions';
import FileUploader from './FileUpload';
import FieldObject from './FieldObject';
import ChatWidget from './PusherChat/ChatWidget';
import IPR from './IPR/IPR';
import IprWidget from './IPR/IprWidget';
import DentaDyanamics from './3DRender';
import SelectAddressInput from './SelectAdress';
import { RadioGroupField } from './RadioGroup';
import { SubmmitterStatuses } from './config';
import { getPermissions } from '../../utils/permissions.utils';
import { formatDate, makeId } from '../../utils';
import defaultCase from '../../utils/defaultCase';
import configApi from '../../config';
import styles from './styles';
import * as config from './constants';
import PdfDownload from './PdfDownload';

const MidlineBlocks = ({ className, disabled }) => (
  <div className={className}>
    <Midline
      source='data.midline.upper'
      disabled={disabled}
      label='Upper Midline'
    />
    <Midline
      source='data.midline.lower'
      disabled={disabled}
      label='Lower Midline'
    />
  </div>
);

const RelationshipBlocks = ({ className, disabled }) => (
  <div className={className}>
    <Relationship
      source='data.relationship.canine'
      disabled={disabled}
      label='Canine relationship'
    />
    <Relationship
      source='data.relationship.molar'
      disabled={disabled}
      label='Molar relationship'
    />
  </div>
);

const TeethGroups = ({ disabled = false, ...rest }) => (
  <div>
    <TeethGroup
      disabled={disabled}
      label='Do not move these teeth'
      source='data.fixedTeeth'
      {...rest}
    />
    <TeethGroup
      disabled={disabled}
      label='Leave these space open'
      source='data.spaceTeeth'
      {...rest}
    />
    {['ste', 'sts', 'reb'].indexOf(process.env.REACT_APP_THEME) !== -1 ? (
      <TeethGroup
        disabled={disabled}
        label='Do not put engagers on these teeth: '
        source='data.notPutTeeth'
        {...rest}
      />
    ) : null}
  </div>
);

const BlockTitle = withStyles(styles)(({ classes, title }) => (
  <FormLabel className={classes.title} component='legend'>
    {title}
  </FormLabel>
));

const SubBlockTitle = withStyles(styles)(({ classes, title }) => (
  <FormLabel className={classes.subTitle} component='legend'>
    {title}
  </FormLabel>
));

const validateCaseID = ((message = 'Required') => (value) =>
  value ? undefined : <span>{message}</span>)();

const CaseCreateToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label='Submit Case' redirect='list' submitOnEnter={true} />
  </Toolbar>
);

export const CaseCreate = withStyles(styles)(({ classes, ...props }) => {
  const s3Folder = randomstring.generate(10);
  const userId = localStorage.getItem('usrId');
  const [userIdPrefix, setUserIdPrefix] = useState('');

  useEffect(() => {
    fetch(`${configApi.api}/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    })
      .then((res) => res.json())
      .then((res) => generatePrefix(res.prefix));
  }, []);

  const generatePrefix = async (userPrefix) => {
      const ID = makeId(4, userPrefix);
      const prefixAlreadyExists = await checkPrefixExistance(ID);
      if(prefixAlreadyExists) {
        generatePrefix(userPrefix);
      } else {
        setUserIdPrefix(ID);
      }
  }

  const checkPrefixExistance = async (prefix) => {
    const response = await fetch(`${configApi.api}/cases/checkCasePrefix`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
      method: 'POST',
      body: { prefix },
    });
    const data = await response.json();

    return data.exists;
  }

  return (
    <Create {...props}>
      <SimpleForm
        classes={{ root: classes.root, card: classes.card }}
        defaultValue={defaultCase}
        toolbar={<CaseCreateToolbar />}
      >
        <BlockTitle title='Existing Conditions' />
        <TextInput
          label='s3Folder'
          source='s3Folder'
          className={classes.hidden}
          defaultValue={s3Folder}
        />
        <TextInput
          label='Case Id'
          source='caseId'
          validate={validateCaseID}
          className={classes.caseId}
          parse={(v) => `${userIdPrefix} - ${v}`}
          format={(v) => (v ? v.replace(`${userIdPrefix} - `, '') : '')}
          required
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>{`${userIdPrefix} - `}</InputAdornment>
            ),
          }}
        />
        <TextInput
          label='Patient Name'
          source='patientName'
          validate={validateCaseID}
          className={classes.caseId}
          required
        />
        <LongTextInput
          className={classes.complaint}
          label='Patient’s chief complaint'
          source='data.description'
          resettable
        />
        <RadioGroupField
          source='data.treat'
          options={config.treat}
          label='Treat'
          className={classes.newLine}
        />
        <MidlineBlocks className={classes.twoBlocks} />
        <RelationshipBlocks className={classes.twoBlocks} />
        <BlockTitle title='Instructions' />
        <Instructions />
        <BlockTitle title='Other Instructions' />
        <TeethGroups labelClassName={classes.label} />
        <BlockTitle title='ADD FILES' />
        <SubBlockTitle title='Submit digital STL files, patient photographs and patient panorex' />
        <FileUploader s3Folder={s3Folder} />
        <BlockTitle title='Address' />
        <SelectAddressInput label='Ship to address' userId={userId} />
      </SimpleForm>
    </Create>
  );
});

const MidlineFields = ({ classes, record }) => {
  return (
    <div className={classes.twoBlocks}>
      <div className={classes.block}>
        <SubBlockTitle title='Midline upper' />
        <FieldObject source='midline.upper' record={record} />
      </div>
      <div className={classes.block}>
        <SubBlockTitle title='Midline lower' />
        <FieldObject source='midline.lower' record={record} />
      </div>
    </div>
  );
};

const RelationshipFields = ({ classes, record }) => {
  return (
    <div className={classes.twoBlocks}>
      <div className={classes.block}>
        <SubBlockTitle title='Relationship molar' />
        <FieldObject source='relationship.molar' record={record} />
      </div>
      <div className={classes.block}>
        <SubBlockTitle title='Relationship canine' />
        <FieldObject source='relationship.canine' record={record} />
      </div>
    </div>
  );
};

const CaseTitle = ({ record }) => <span>{record.caseId || record.id}</span>;

const EditForm = ({ classes, record, chat }) => {
  const [isDisabled, setIsDisabled] = useState(false);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      {isDisabled ? (
        <>
          <DentaDyanamics
            title={<BlockTitle title='TP' />}
            setIsDisabled={setIsDisabled}
            record={record}
          />
          <IprWidget
            title={<BlockTitle title='IPR and Attachments' />}
            record={record}
          />
          <PdfDownload record={record} />
        </>
      ) : null}
      <div style={{ width: '50%' }}>
        <BlockTitle title='Existing Conditions' />
        <TextInput
          label='Case Id'
          source='caseId'
          validate={validateCaseID}
          className={classes.caseId}
          disabled={isDisabled}
          required
        />
        <TextInput
          label='Patient Name'
          source='patientName'
          className={classes.caseId}
          validate={validateCaseID}
          disabled={isDisabled}
          required
        />
        <LongTextInput
          className={classes.complaint}
          label='Patient’s chief complaint'
          source='data.description'
          disabled={isDisabled}
          resettable
        />
        <RadioGroupField
          source='data.treat'
          options={config.treat}
          label='Treat'
          disabled={isDisabled}
          className={classes.newLine}
        />
        <MidlineBlocks className={classes.twoBlocks} disabled={isDisabled} />
        <RelationshipBlocks
          className={classes.twoBlocks}
          disabled={isDisabled}
        />
        <BlockTitle title='Instructions' />
        <Instructions disabled={isDisabled} />
      </div>
      {!isDisabled ? (
        <>
          <DentaDyanamics
            title={<BlockTitle title='TP' />}
            setIsDisabled={setIsDisabled}
            record={record}
          />

          <IprWidget title={<BlockTitle title='IPR' />} record={record} />
        </>
      ) : null}
      <div style={{ width: '50%' }}>
        <BlockTitle title='Other Instructions' />
        <TeethGroups disabled={isDisabled} labelClassName={classes.label} />
        <BlockTitle title='ADD FILES' />
        <SubBlockTitle title='Submit digital STL files, patient photographs and patient panorex' />
        <FileUploader record={record} />
        <BlockTitle title='Address' />
        <SelectAddressInput
          label='Ship to address'
          disabled={isDisabled}
          userId={record.submiterId}
          record={record}
        />
      </div>
    </div>
  );
};

const ShowForm = ({ classes, record }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <FunctionField
        style={{ width: '100%' }}
        addLabel={false}
        render={() => {
          const notContainTp = !record.tp || record.tp.length === 0;
          const title = notContainTp ? 'No TP' : 'TP';

          return (
            <>
              <BlockTitle title={title} />
              {!notContainTp && (
                <iframe
                  className='iprIframe'
                  title={record.tp}
                  src={record.tp} />
              )}
              <IprWidget record={record} />
              <PdfDownload record={record} /> 
            </>
          );
        }}
      />
      <div style={{ width: '50%' }}>
        <BlockTitle title='Existing Conditions' />
        <div style={{ marginBottom: '25px' }}>
          <span className={classes.subLabel}>Patient Name</span>
          <div className={classes.subValue}>
            {record.patientName || 'empty'}
          </div>
        </div>
        <div style={{ marginBottom: '25px' }}>
          <span className={classes.subLabel}>Patient’s chief complaint</span>
          <div className={classes.subValue}>
            {record.data.description || 'empty'}
          </div>
        </div>
        <div>
          <span className={classes.subLabel}>Treat</span>
          <span className={classes.subValue}>
            {record.data.treat || 'empty'}
          </span>
        </div>
        <MidlineFields record={record} classes={classes} />
        <RelationshipFields record={record} classes={classes} />
        <BlockTitle title='Instructions' />
        <FieldObject source='instructions' record={record} />
      </div>
      <BlockTitle title='IPR and Attachments' />
      <IPR source='data.iprTeeth' record={record} />
      <BlockTitle title='Other Instructions' />
      <TeethGroups
        record={record}
        disabled={true}
        labelClassName={classes.label}
      />
      <DateField label='Publication date' source='created' />
      <BlockTitle title='ADD FILES' />
      <SubBlockTitle title='Submit digital STL files, patient photographs and patient panorex' />
      <FileUploader record={record} />
      <BlockTitle title="Address" />
      <SubBlockTitle title={record.address || "User did not pick any address."} />
    </div>
  );
};

const ShowFormByStatus = ({ classes, permissions, record }) => {
  const userRights = getPermissions(permissions);
  return userRights !== 'CASE_MANAGER' &&
    SubmmitterStatuses.indexOf(record.status) !== -1 ? (
      <EditForm classes={classes} record={record} />
    ) : (
      <ShowForm classes={classes} record={record} />
    );
};

const CaseActions = ({ data, permissions }) => (
  <CardActions>
    <StatusButton record={data} permissions={permissions} />
  </CardActions>
);

const CurrentShipment = ({ record }) => {
  const lastShipmentObject = record.shipments[record.shipments.length - 1];

  if (!lastShipmentObject) {
    return '';
  }
  const lastShipment = {
    last: [record.shipments[record.shipments.length - 1]],
  };

  return (
    <div>
      <BlockTitle title='Current Status Case' />
      <ArrayField label={false} record={lastShipment} source='last'>
        <Datagrid>
          <RaTextField
            source='productType'
            label='Product Type'
            sortable={false}
          />
          <RaTextField source='quantity' label='Quantity' sortable={false} />
          <FunctionField
            label='Unlimited'
            render={({ isUnlimit }) =>
              isUnlimit ? 'Yes' : 'No'
            }
          />
          <RaTextField source='price' sortable={false} />
          <FunctionField
            label='Case Submitted'
            render={(record) => formatDate(record.created)}
          />
          <FunctionField
            label='Date Paid'
            render={(record) => formatDate(record.payedAt)}
          />
          <FunctionField
            label='Card No'
            render={(record) =>
              record.cardLast4 ? `xxxx xxxx xxxx ${record.cardLast4}` : ''
            }
          />
          <FunctionField
            label="Treatment plan created"
            render={record => formatDate(record.treatmentPlanCreated)}
          />
          <FunctionField
            label="Treatment plan approved"
            render={(record) => formatDate(record.treatmentPlanApproved)}
          />
          <RaTextField
            source='trackingNumber'
            label='Tracking Number'
            sortable={false}
          />
          <BooleanField source='isReplacement' sortable={false} />
          <RaTextField source='comment' sortable={false} />
        </Datagrid>
      </ArrayField>
    </div>
  );
};

const PostCreateToolbar = (props) => (
  <Toolbar
    {...props}
    style={{ display: 'flex', justifyContent: 'space-between' }}
  >
    <SaveButton />
    <DeleteButton undoable={false} />
  </Toolbar>
);

export const CaseEdit = withStyles(styles)(
  ({ classes, permissions, ...props }) => {
    return (
      <Edit
        {...props}
        actions={<CaseActions permissions={permissions} />}
        title={<CaseTitle />}
      >
        <TabbedForm toolbar={<PostCreateToolbar />}>
          <FormTab label='General'>
            <CurrentShipment />
            <ChatWidget source='chat' permissions={permissions} />
            <ShowFormByStatus classes={classes} permissions={permissions} />
          </FormTab>
          <FormTab label='Shipments'>
            <ArrayField source='shipments' label={false}>
              <Datagrid>
                <RaTextField
                  source='productType'
                  label='Product Type'
                  sortable={false}
                />
                <RaTextField
                  source='quantity'
                  label='Quantity'
                  sortable={false}
                />
                <RaTextField source='price' sortable={false} />
                <FunctionField
                  label='Date case submitted'
                  render={(record) => formatDate(record.created)}
                />
                <FunctionField
                  label='Date case paid'
                  render={(record) => formatDate(record.payedAt)}
                />
                <FunctionField
                  label='Card No'
                  render={(record) =>
                    record.cardLast4 ? `xxxx xxxx xxxx ${record.cardLast4}` : ''
                  }
                />
                <RaTextField
                  source='trackingNumber'
                  label='Tracking Number'
                  sortable={false}
                />
                <BooleanField source='isReplacement' sortable={false} />
                <RaTextField
                  label='Comments'
                  source='comment'
                  sortable={false}
                />
              </Datagrid>
            </ArrayField>
            {/* <ShipmentsField source="shipments" className={classes.row} /> */}
          </FormTab>
          <FormTab label='History'>
            <ArrayField source='history' label={false}>
              <Datagrid>
                <RaTextField
                  source='sourceStatus'
                  label='Case Status'
                  sortable={false}
                />
                <FunctionField
                  label='Case Status Date'
                  render={(record) => formatDate(record.created)}
                />
                <RaTextField
                  source='targetStatus'
                  label='Next Step'
                  sortable={false}
                />
              </Datagrid>
            </ArrayField>
            {/* <HistoryField source="history" className={classes.row} /> */}
          </FormTab>
        </TabbedForm>
      </Edit>
    );
  },
);
