import React, { useState, useEffect } from 'react'
import { CSVReader } from 'react-papaparse'

export default function IPRUploader({ setTooth }) {
  const [csvData, setCsvData] = useState();

  function handleOnDrop(data) {
    console.log('---------------------------')
    console.log('string array ', data);
    setCsvData(data);
    console.log('---------------------------')
  }

  useEffect(() => {
    if (csvData) {
      let markUpUpper = {
        teethNr: 1,
        steps: [],
      }
      let markUpDown = {
        teethNr: 10,
        steps: [],
      };

      let i = 0;
      let totalCount = 0;
      let lastRow = false;

      while (!lastRow) {  //Removing empty rows
        if (csvData[i].data[0].indexOf('Total') > -1) {
          totalCount++;
          if(totalCount === 2) lastRow = true;
        }

        if (csvData[i].data.length === 1 && csvData[i].data[0] === "") {
          csvData.splice(i, 1);
          continue;
        }

        i++;
      }

      i = 2;

      while (csvData[i].data[0].indexOf('Total') === -1) {
        markUpUpper.steps.push(i);
        i++;
      }

      markUpDown.teethNr = i + 2;
      i = i + 3;

      while (csvData[i].data[0].indexOf('Total') === -1) {
        markUpDown.steps.push(i);
        i++;
      }

      console.log(markUpDown);
      console.log(markUpUpper);


      const getInfoAboutTeeth = (teethArray, idx, upper = true) => {
        let markup;

        if (upper) {
          markup = markUpUpper;
        } else {
          markup = markUpDown;
        }

        const initNumber = csvData[markup.teethNr].data[idx];
        const number = parseInt(initNumber.match(/\d/g).join(""));
        const side = initNumber.replace(/[0-9]/g, '') === 'd' ? 'left' : 'right';
        const step = markup.steps.findIndex((step) => csvData[step].data[idx]);
        let teeth = teethArray.find((t) => t.number === number);
        let value = {};

        if (step !== -1) {
          csvData[markup.steps[step]].data[idx] = csvData[markup.steps[step]].data[idx].replace(',', '.');
          value = {
            [`${side}SideStep`]: step,
            [`${side}SideMm`]: parseFloat(csvData[markup.steps[step]].data[idx]),
          };
        }


        if (!teeth) {
          teeth = {
            id: 0,
            number,
          };

          Object.assign(teeth, value);

          teethArray.push(teeth);
        } else {
          const index = teethArray.findIndex((t) => t.number === number);

          teethArray[index] = { ...teeth, ...value };
        }

        return [...teethArray];
      }

      const parseData = () => {
        const teethArrayUp = [];
        for (let i = 1; i < 33; i++) {
          getInfoAboutTeeth(teethArrayUp, i);
        }

        teethArrayUp.splice(0, 2);
        teethArrayUp.splice(-2, 2);

        const teethArrayDown = [];

        for (let i = 1; i < 33; i++) {
          getInfoAboutTeeth(teethArrayDown, i, false);
        }

        teethArrayDown.splice(0, 2);
        teethArrayDown.splice(-2, 2);

        const result = [...teethArrayUp, ...teethArrayDown].map((item, idx) => {
          item.id = idx;

          return item;
        });

        return result;
      }

      setTooth(parseData());
    }
  }, [csvData]);




  function handleOnError(err, file, inputElem, reason) {
    console.log(err)
  }

  function handleOnRemoveFile(data) {
    console.log('---------------------------')
    console.log(data)
    setCsvData();
    console.log('---------------------------')
  }

  return (
    <CSVReader
      onDrop={handleOnDrop}
      onError={handleOnError}
      addRemoveButton
      onRemoveFile={handleOnRemoveFile}
    >
      <span>Drop CSV file here or click to upload.</span>
    </CSVReader>
  )
}
