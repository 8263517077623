import React from 'react';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import config from '../../../../config';
import { isSTE } from '../../../../utils/themes';

const QtyPrice = ({
  quantity,
  changeQuantity,
  price,
  setPrice,
  classes,
  isPrice,
  isAmount,
  isRetainer,
  setUnlimitedPlan,
  unlimitedPlan,
}) => {
  return (
    <>
      <DialogContentText>Setup quantity and price:</DialogContentText>
      <div className={classes.InputRow}>
        {isAmount && (
          <TextField
            margin="dense"
            id="trackNum"
            label="Quantity"
            type="number"
            error={quantity < 1}
            value={quantity}
            className={classes.halfWidth}
            onChange={(e) => changeQuantity(e.target.value)}
            fullWidth
          />
        )}
        {isPrice && (
          <TextField
            className={classes.halfWidth}
            margin="dense"
            id="trackNum"
            label="Price ( $ )"
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            fullWidth
          />
        )}
      </div>
      {!isRetainer && (
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => {
                setUnlimitedPlan(e.target.checked);

                if (!isSTE()) {
                  setPrice(config.unlimitedPrice[process.env.REACT_APP_THEME]);
                }
              }}
              value="checkedUnlimitPlan"
              color="primary"
              checked={unlimitedPlan}
            />
          }
          label="Unlimited Plan"
        />
      )}
    </>
  );
};

export default QtyPrice;
