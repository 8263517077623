import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import configApi from '../../../config';
import generatePdfDocument from '../../../services/pdfService';
import withEnv from '../../../hoc/withEnv';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const pdfEnvs = ['sts', 'ste'];

const PdfDownload = ({ record }) => {
  const [downloadingPdf, setDownloadingPdf] = useState(false);

  const downloadPdf = async () => {
    const headers = { Authorization: `Bearer ${localStorage.getItem('authToken')}` };

    setDownloadingPdf(true);

    const result = await fetch(`${configApi.api}/users/${record.submiterId}`, { headers });
    const submiter = await result.json();

    const docDefinition = generatePdfDocument(record, submiter);
    const pdfFileName = record.caseId.split("-")[0].trim();

    pdfMake.createPdf(docDefinition).download(pdfFileName);

    setDownloadingPdf(false);
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={downloadPdf}
      disabled={downloadingPdf}
      style={{ marginTop: 50 }}
    >
      Download IPR and Attachment Guide as 	&nbsp;<PictureAsPdfIcon />
    </Button>
  );
};

export default withEnv(PdfDownload, pdfEnvs);
