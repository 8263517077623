const config = {
  api: 'https://api.straightteethsolutions.com',
  routes: {
    users: 'users',
    cases: 'cases',
    auth: 'auth/login',
  },
  unlimitedPrice: {
    ste: 1249,
    sts: 1371,
    reb: 1371,
    health: 1371,
  }
};

export default config;
