import { desialMesialConfig } from '../components/Cases/IPR/config';

export const getSide = (number, dm) => {
  return desialMesialConfig[number][dm];
}

export const SIDES = {
  right: 'right',
  left: 'left',
};

export const getMiddleValue = (tooth, next) => {
  if (!next) {
    return null;
  }

  const sides = {
    d: 'left',
    m: 'right',
  };
  const dmCurrent = sides[desialMesialConfig[tooth.number][SIDES.right]];
  const dmNext = sides[desialMesialConfig[next.number][SIDES.left]];

  const currentTooth = {
    mm: tooth[`${dmCurrent}SideMm`] || 0,
    step: tooth[`${dmCurrent}SideStep`],
  };

  const nextTooth = {
    mm: next[`${dmNext}SideMm`] || 0,
    step: next[`${dmNext}SideStep`],
  };

  if (currentTooth.mm || nextTooth.mm) {
    const sum = parseFloat(currentTooth.mm) + parseFloat(nextTooth.mm);

    return {
      mm: sum.toFixed(1) || 0,
      step: currentTooth.step || nextTooth.step,
    };
  }

  return null;
}