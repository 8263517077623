import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Elements } from 'react-stripe-elements';
import { showNotification } from 'react-admin';
import { push } from 'react-router-redux';
import config from '../../../config';
import { SubmmitterStatuses, EditorStatuses } from '../config';
import Modal from '../Modal/';
import { fetchEntityChanges } from '../../../utils';
import PaymentModal from '../PaymentModal';
import { fontStyle } from '../../../theme';
import { disableStatusBtn } from '../../../utils/disable';
import { isSTE } from '../../../utils/themes';

const actionsBtnBackgroundColor = (env) => {
  const envHandler = {
    default: '#84C5E7',
    sts: '#00b3c5',
    ste: '#c158dc',
  };

  return envHandler[env] ? envHandler[env] : envHandler['default'];
};

const styles = {
  root: {
    backgroundColor: actionsBtnBackgroundColor(process.env.REACT_APP_THEME),
    fontFamily: `'${fontStyle}', 'sans-serif'`,
    fontWeight: 700,
    margin: '0 10px',
  },
};

const StatusActions = withStyles(styles)((props) => {
  const { classes, record, push, showNotification, permissions } = props;
  const [modal, setModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [status, setStatus] = useState(record ? record.status : null);
  const [replacement, setReplacement] = useState(false);
  const [isTrackNumber, setIsTrackNumber] = useState(false);
  const [isNumQty, setIsNumQty] = useState(false);
  const [isRetainer, setIsRetainer] = useState(false);
  const [retainerPrice, setRetainerPrice] = useState(0);
  const [isAmount, setIsAmount] = useState(false);
  const [isPrice, setIsPrice] = useState(false);
  const [isReceivePayment, setIsReceivePayment] = useState(false);
  const [newStateBtns, setNewStateBtns] = useState(null);

  const [cards, setCards] = useState([]);

  if (record && !newStateBtns) {
    fetch(`${config.api}/transitions/${record.status}`)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setNewStateBtns(result);
      });
  }
  const requestUrl = `${config.api}/${config.routes.cases}/${record ? record.id : null}`;

  const handleResponse = () => {
    showNotification('Status Changed');
    push(`/${config.routes.cases}`);
  };

  const getCards = () => {
    fetchEntityChanges({
      url: `${config.api}/customer/${record.id}/cards`,
      method: 'GET',
      handleResponse: handleGetCardsResponse,
    });
  };

  const handleGetCardsResponse = (data) => {
    setCards(data);
  };

  const hadlePaymentResponse = () => {
    setPaymentModal(false);
    showNotification('Payment status: Successful');
    push(`/${config.routes.cases}`);
  };

  const confirmChangeStatus = (status) => {
    setModal(false);
    fetchEntityChanges({ url: requestUrl, newData: { status }, handleResponse });
  };

  const getTrackingNumber = (
    { trackNum, quantity, price, unlimitedPlan },
    status,
    shipmentType,
    comment,
  ) => {
    const updatedRecord = {
      trackingNumber: trackNum,
      quantity: parseInt(quantity, 10),
      price: parseFloat(price, 10),
      isUnlimit: unlimitedPlan,
      status,
      shipmentType,
      comment,
    };
    setModal(false);
    fetchEntityChanges({
      url: requestUrl,
      newData: updatedRecord,
      handleResponse,
    });
  };

  const payShipment = (amount, currency, cardToken, tp = '') => {
    const newData = { amount, currency, cardToken, tp };
    const url = `${config.api}/${config.routes.cases}/${record.id}/shipment`;
    fetchEntityChanges({
      url,
      method: 'PUT',
      newData,
      handleResponse: () => hadlePaymentResponse(),
    });
  };

  const setPendingPayment = (tp = '', statuses) => {
    const url = `${config.api}/${config.routes.cases}/${record.id}/pendingPayment`;
    const newData = { tp, statuses };
    fetchEntityChanges({
      url,
      method: 'PUT',
      newData,
      handleResponse: async (res) => {
        await fetchEntityChanges({
          url: requestUrl,
          newData: {
            status: 'PENDING_PATIENT_PAYMENT',
          },
          handleResponse,
        });
      },
    });
  };

  const receivePaymentHandler = (note, status) => {
    const { id } = record;
    const {
      api,
      routes: { cases },
    } = config;

    fetchEntityChanges({
      url: `${api}/${cases}/${id}/receivePayment`,
      method: 'PUT',
      newData: { note, status },
      handleResponse: (res) => {
        fetchEntityChanges({
          url: requestUrl,
          newData: {
            status: 'PRODUCE_ALIGNER',
          },
          handleResponse,
        });
      },
    });
  };

  const handleStatusClick = async (status) => {
    const modalStatuses = await fetch(`${config.api}/modals`).then((modalsFetch) =>
      modalsFetch.json(),
    );
    const paymentModalStatuses = await fetch(`${config.api}/payment-modals`).then((resultFetch) =>
      resultFetch.json(),
    );
    setStatus(status);

    if (isSTE() && status === 'ORDER_RETAINER') {
      setIsRetainer(true);
      setIsPrice(true);
      setIsAmount(true);
      setModal(true);

      return;
    }

    if(isSTE() && status === 'PRODUCE_RETAINER') {
      getCards();
      setIsRetainer(true);
      setPaymentModal(true);

      return;
    }

    if (
      status === 'IMPROVE_SETUP' ||
      (modalStatuses.indexOf(status) === -1 && paymentModalStatuses.indexOf(record.status) === -1)
    ) {
      return fetchEntityChanges({ url: requestUrl, newData: { status }, handleResponse });
    }

    if (modalStatuses.indexOf(status) !== -1) {
      if (['PLAN_AVAILABLE', 'REPLACEMENT_PLAN_AVAILABLE'].indexOf(status) !== -1) {
        setIsAmount(true);
      }

      if (['PLAN_AVAILABLE', 'REPLACEMENT_PLAN_AVAILABLE'].indexOf(status) !== -1) {
        setIsPrice(true);
      }

      if (['ALIGNER_SHIPPED', 'RETAINER_SHIPPED', 'REPLACEMENTS_SHIPPED'].indexOf(status) !== -1) {
        setIsTrackNumber(true);
      }

      if (status === 'REPLACEMENT_REQUIRED') {
        setReplacement(true);
      }

      if (status === 'RECEIVE_PAYMENT') setIsReceivePayment(true);

      if (status === 'ORDER_RETAINER') {
        setIsAmount(true);
      }

      setModal(true);
    }

    if (paymentModalStatuses.indexOf(record.status) !== -1) {
      getCards();
      setPaymentModal(true);
    }
  };

  const showButtons = () => {
    if (permissions.indexOf('ADMIN') !== -1) {
      return true;
    }

    if (permissions.indexOf('PROVIDER') !== -1) {
      if (SubmmitterStatuses.indexOf(record.status) !== -1) {
        return true;
      }
    } else {
      if (EditorStatuses.indexOf(record.status) !== -1) {
        return true;
      }
    }
    return false;
  };

  const retainerNext = (quantity, price) => {
    console.log(quantity, price, status);

    fetchEntityChanges({
      handleResponse: () => {
        getCards();

        setRetainerPrice(price);
        setIsRetainer(true);
        setPaymentModal(true);

        setModal(true);
      },
      url: requestUrl,
      newData: { status, quantity, price, productType: 'RETAINER' },
    });
  };

  const showStatusBtns = !!newStateBtns && !!newStateBtns.length && showButtons();

  const translations = JSON.parse(localStorage.getItem('translations'));

  const btnText = {
    PRODUCE_ALIGNER: 'Order now',
  };

  return (
    <>
      {showStatusBtns &&
        newStateBtns.map((stateBtn) => (
          <Button
            className={classes.root}
            label={translations ? translations[stateBtn.id] : ''}
            key={stateBtn.id}
            onClick={() => handleStatusClick(stateBtn.id)}
            disabled={disableStatusBtn(stateBtn.id)}
          >
            {translations ? btnText[stateBtn.id] || translations[stateBtn.id] : ''}
          </Button>
        ))}
      <Modal
        open={modal}
        status={status}
        isTrackNumber={isTrackNumber}
        isPrice={isPrice}
        isAmount={isAmount}
        isNumQty={isNumQty}
        isRetainer={isRetainer}
        isReceivePayment={isReceivePayment}
        handleTrackNum={getTrackingNumber}
        confirmChangeStatus={confirmChangeStatus}
        onClose={() => setModal(false)}
        shipments={record ? record.shipments : []}
        replacement={replacement}
        receivePaymentHandler={receivePaymentHandler}
        retainerNext={retainerNext}
      />
      {paymentModal && (
        <Elements>
          <PaymentModal
            open={paymentModal}
            onClose={() => setPaymentModal(false)}
            getCards={getCards}
            cards={cards}
            payShipment={payShipment}
            record={record}
            shipments={record ? record.shipments : []}
            setPendingPayment={setPendingPayment}
            isRetainer={isRetainer}
            retainerPrice={retainerPrice}
          />
        </Elements>
      )}
    </>
  );
});

StatusActions.propTypes = {
  statusLabel: PropTypes.string,
  targetStatus: PropTypes.string,
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
};

export default withRouter(
  connect(null, {
    showNotification,
    push,
  })(StatusActions),
);
