import React from 'react';

const withEnv = (Wrappedcomponent, envs = []) => (props) => {
  if(!envs.includes(process.env.REACT_APP_THEME)) {
    return null;
  }

  return <Wrappedcomponent {...props} />
}


export default withEnv;