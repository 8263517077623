import iprTeeth, { arrow, reversedArrow } from '../utils/svgTeeth';
import { getMiddleValue } from './iprService';

const coloredTeethByEnv = () => {
  const envColor = {
    sts: '#00b3c5',
    ste: '#7c209a',
  }

  return envColor[process.env.REACT_APP_THEME];
}

const svgColor = coloredTeethByEnv();

const drawTable = (columnsNumber, body) => {
  return {
    layout: 'noBorders',
    table: {
      body,
      headerRows: 1,
      widths: (new Array(columnsNumber)).fill('*'),
    }
  };
}

const drawTeeth = (data, startIndex, endIndex) => {
  const splicedData = data.slice(startIndex, endIndex);

  return splicedData.map((teeth, index) => {
    const iprsvgIndex = index + startIndex
    let svg = iprTeeth[iprsvgIndex];

    if (teeth.isAttachment) {
      svg = svg.replace('fill="#4B4B4D"', `fill="${svgColor}"`)
    }

    return { svg };
  });
}

const writeStepAndTeethGap = (data, startIndex, endIndex, drawTriangle = false) => {
  const splicedData = data.slice(startIndex, endIndex);
  const isUpperTeethRow = startIndex === 0;

  return splicedData.reduce((acc, teeth, index) => {
    const sum = getMiddleValue(teeth, splicedData[index + 1]);
    const MARGIN_RIGHT = -0.5;
    const DEFAULT_MARGIN = 0;
    let step = "";
    let columnContent = "";
    let arrowSvg = { text: columnContent };

    if (sum) {
      columnContent = isUpperTeethRow ? reversedArrow(svgColor) : arrow(svgColor);
      step = `${sum.mm} \n Before step: ${sum.step}`;
      arrowSvg = {
        svg: columnContent,
        margin: [MARGIN_RIGHT, DEFAULT_MARGIN, DEFAULT_MARGIN, DEFAULT_MARGIN],
      };
    }
    
    if (drawTriangle) {
      //The teeth are rendered in a table row with a number of columns
      //In order to put arrows and text between them we create a row with 2 times more columns
      //The columns are rendered with array https://pdfmake.github.io/docs/0.1/document-definition-object/tables/
      //So we render empty column then render column with content then again empty column and so on
      acc.push("", { ...arrowSvg, fontSize: 5, alignment: 'center' });
    } else {
      acc.push("", { text: step, fontSize: 5, alignment: 'center' });
    }

    return acc;
  }, []);
}

const renderSteps = (columnsNumber, data, startIndex, endIndex, drawTriangle = false) => {
  const contentRow = [ writeStepAndTeethGap(data, startIndex, endIndex, drawTriangle) ];

  return drawTable(columnsNumber, contentRow);
}

const renderTeeth = (columnsNumber, data) => {
  const emptyRow = Array(columnsNumber).fill('');
  const topTeeth = drawTeeth(data, 0, columnsNumber);
  const bottomTeeth = drawTeeth(data, columnsNumber, data.length);
  const teethContent = [ topTeeth, emptyRow, emptyRow, emptyRow, bottomTeeth];

  return drawTable(columnsNumber, teethContent);
}

export default (record, doctor) => {
  const BOTTOM_MARGIN = 40;
  const data = record.data.iprTeeth;
  const iprTeethLength = data.length;
  const halfOfTeeth = iprTeethLength / 2;
  const caseIdRow = record.caseId.split("-")[0].trim();
  const patientNameRow = `Patient Name: ${record.patientName}`;
  const doctorsNameRow = { text: `Dr. ${doctor.firstname} ${doctor.lastname}`, margin: [0, 0, 0, BOTTOM_MARGIN]};
  const upperStepsNumber = renderSteps(iprTeethLength, data, 0, halfOfTeeth);
  const upperArrows = renderSteps(iprTeethLength, data, 0, halfOfTeeth, true);
  const teeth = renderTeeth(halfOfTeeth, data);
  const bottomArrows = renderSteps(iprTeethLength, data, halfOfTeeth, iprTeethLength, true);
  const bottomStepsNumber = renderSteps(iprTeethLength, data, halfOfTeeth, iprTeethLength);

  return {
    content: [
      caseIdRow,
      patientNameRow,
      doctorsNameRow,
      upperStepsNumber,
      upperArrows,
      teeth,
      bottomArrows,
      bottomStepsNumber,
    ],
  };
}