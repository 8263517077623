import React, { Component } from 'react';
import PaperPlane from '../../UI/PaperPlane';

class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(e) {
    this.setState({ message: e.target.value });

    this.props.setMessage(e.target.value)
  }
 
  handleSubmit(e) {
    e.preventDefault();
    const { message } = this.state;

    this.props.onSubmit(message);

    this.setState({ message: '' });
  }
  render() {
    return (
      <form onSubmit={this.handleSubmit} className="input-field">
        <textarea
          className="message-input"
          placeholder="Type your message"
          type="textarea"
          onChange={this.handleChange}
          value={this.state.message}
        />
        <button
          style={{
            'backgroundColor': this.props.colorChange(process.env.REACT_APP_THEME),
          }}
          className="message-submit"
          type="submit"
        >
          <PaperPlane />
        </button>
      </form>
    )
  }
}
export default Input;
