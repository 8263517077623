export default [
  `
    <svg width="35" height="30" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.66795 7.55601C10.0399 5.84131 9.61325 4.53751 8.86705 3.39411C6.13655 -0.790195 1.95445 0.32531 0.427846 4.81641C0.00854649 6.05011 -0.320353 8.04371 0.535647 8.98221C1.25635 9.77251 2.17625 10.6038 3.49185 11.0741C5.22285 11.6927 7.47455 10.1941 8.75345 9.19191C9.20145 8.84081 9.49735 8.34221 9.66795 7.55601Z" fill="#4B4B4D"/>
      <text stroke="null" transform="matrix(0.22617145011998027,0,0,0.20729330973466592,3.640187237166999,7.654207915475816) " xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="22" id="svg_3" y="-0.33581" x="-0.22881" stroke-opacity="null" stroke-width="0" fill="#fcfcfc">3</text>
    </svg>  
  `,
  `
    <svg width="35" height="30" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.98404 7.0204C10.2149 5.3511 9.78254 4.1616 9.01364 3.1037C5.05584 -2.342 0.357842 2.2212 0.271442 7.1274C0.253042 8.1717 0.368644 8.4132 0.930744 8.9924C3.36564 11.5013 4.92814 11.6739 7.77784 9.9207C8.89394 9.234 9.75414 8.6828 9.98404 7.0204Z" fill="#4B4B4D"/>
      <text stroke="null" transform="matrix(0.22617145011998027,0,0,0.20729330973466592,3.640187237166999,7.654207915475816) " xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="22" id="svg_3" y="-0.33581" x="-0.22881" stroke-opacity="null" stroke-width="0" fill="#fcfcfc">4</text>
    </svg>  
  `,
  `
    <svg width="35" height="30" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.10095 0.7291C-0.166453 2.148 -0.531252 9.4794 1.77945 11.623C2.49275 12.2847 4.77045 13.2818 6.05005 12.6636C6.65805 12.3698 7.17735 11.9258 7.71475 11.5878C9.80545 10.2727 11.1193 8.9843 10.8302 6.1879C10.5072 3.065 7.72105 0.0231999 5.10095 0.7291Z" fill="#4B4B4D"/>
    <text stroke="null" transform="matrix(0.22617145011998027,0,0,0.20729330973466592,3.640187237166999,7.654207915475816) " xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="22" id="svg_3" y="2.93581" x="3.22881" stroke-opacity="null" stroke-width="0" fill="#fcfcfc">5</text>
    </svg>
  `,
  `
    <svg width="35" height="30" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.732247 8.71721C1.25485 12.3661 4.36194 15.4857 7.37114 14.5257C8.89884 14.0383 10.4194 12.8009 11.131 11.9198C12.3168 10.4517 12.1844 8.9615 11.8912 6.8324C11.5989 4.709 11.5121 3.2373 10.2567 2.1089C8.27854 0.330705 3.10204 -0.429096 1.39764 3.176C0.757842 4.5294 0.497747 7.08011 0.732247 8.71721Z" fill="#4B4B4D"/>
    <text stroke="null" transform="matrix(0.22617145011998027,0,0,0.20729330973466592,3.640187237166999,7.654207915475816) " xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="22" id="svg_3" y="4.33581" x="5.22881" stroke-opacity="null" stroke-width="0" fill="#fcfcfc">6</text>
    </svg>
  `,
  `
    <svg width="35" height="30" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.17364 0.362596C3.41604 0.658996 2.32425 1.7585 1.62145 2.9224C0.750147 4.3653 0.673146 6.2339 0.729646 8.1578C0.778346 9.8201 0.875444 12.1091 2.22514 12.6784C3.60184 13.259 6.29544 13.102 7.88925 13.0131C9.94394 12.8985 11.5355 12.935 12.0546 11.1626C12.5046 9.6264 12.0445 7.1974 11.7812 5.6419C11.1847 2.1195 8.15684 -0.140504 5.17364 0.362596Z" fill="#4B4B4D"/>
    <text stroke="null" transform="matrix(0.22617145011998027,0,0,0.20729330973466592,3.640187237166999,7.654207915475816) " xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="22" id="svg_3" y="4.33581" x="5.22881" stroke-opacity="null" stroke-width="0" fill="#fcfcfc">7</text>
    </svg>  
  `,
  `
    <svg width="35" height="30" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6287 13.3707C10.3648 14.3322 2.71745 14.4376 1.33535 12.8366C0.579049 11.9605 0.913747 8.15641 1.02085 6.78361C1.20895 4.37051 1.31054 3.26891 2.70354 1.90681C4.95304 -0.292688 10.5459 -0.196693 12.4942 2.33661C13.174 3.22141 13.4714 4.03701 13.6152 5.13411C13.6841 5.65981 13.7158 6.19681 13.7536 6.72571C13.7761 7.04051 13.8015 7.35431 13.8292 7.66871C13.8371 7.75791 13.8379 7.853 13.8415 7.943C13.8436 7.994 13.8469 8.04731 13.8479 8.09791C13.8486 8.13591 13.8389 8.162 13.8327 8.1988C13.7981 8.4028 13.8091 8.666 13.8203 8.873C13.8398 9.2355 13.8741 9.5977 13.8949 9.9605C13.9494 10.913 13.9544 12.1834 13.2645 12.9285C13.0843 13.1231 12.8722 13.2668 12.6287 13.3707Z" fill="#4B4B4D"/>
    <text stroke="null" transform="matrix(0.22617145011998027,0,0,0.20729330973466592,3.640187237166999,7.654207915475816) " xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="22" id="svg_3" y="5.33581" x="9.22881" stroke-opacity="null" stroke-width="0" fill="#fcfcfc">8</text>
    </svg>
  `,
  `
    <svg width="35" height="30" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.630043 8.0591L0.672641 7.6762C0.672641 7.6755 0.672746 7.6748 0.672846 7.6741C0.672946 7.6734 0.673039 7.6728 0.673139 7.6721L0.673441 7.6694L0.674145 7.662L0.673041 7.6721C0.673141 7.6712 0.673341 7.6703 0.673441 7.6694C0.673741 7.667 0.673945 7.6646 0.674145 7.662L0.673441 7.6694L0.673139 7.6721L0.673441 7.6694L0.674145 7.662C0.681245 7.577 0.687146 7.4914 0.693646 7.4063C0.749346 6.6772 0.803148 5.9383 0.909848 5.2149C1.08635 4.0176 1.41274 2.8334 2.30834 1.9659C3.59314 0.723097 5.89125 0.242302 8.29075 0.403202C10.8632 0.951202 12.7798 1.8503 13.2722 4.7219C13.5461 6.3191 13.7148 9.8508 13.5463 11.5694C13.4552 12.4989 13.309 12.804 12.7281 13.157C10.9284 14.2508 6.59684 14.1814 4.31374 13.93C-0.256756 13.4268 0.752541 12.0808 0.630941 8.0892V8.0889C0.630941 8.089 0.630941 8.0891 0.630941 8.0892V8.0889C0.630641 8.079 0.630343 8.0691 0.630043 8.0591Z" fill="#4B4B4D"/>
    <text stroke="null" transform="matrix(0.22617145011998027,0,0,0.20729330973466592,3.640187237166999,7.654207915475816) " xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="22" id="svg_3" y="6.33581" x="9.22881" stroke-opacity="null" stroke-width="0" fill="#fcfcfc">9</text>
    </svg>
  `,
  `
    <svg width="35" height="30" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7156 9.143C12.053 4.9953 11.2874 1.88321 8.26736 0.609308C5.67566 -0.483992 1.90196 1.27521 0.911255 4.72681C0.554655 5.96921 0.161953 8.884 0.276753 10.2437C0.465853 12.4851 1.49905 12.7611 3.63975 12.9476C5.18505 13.0821 7.95396 13.232 9.44206 12.9304C11.331 12.5474 11.5348 11.3648 11.7156 9.143Z" fill="#4B4B4D"/>
    <text stroke="null" transform="matrix(0.22617145011998027,0,0,0.20729330973466592,3.640187237166999,7.654207915475816) " xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="22" id="svg_3" y="4.33581" x="-0.22881" stroke-opacity="null" stroke-width="0" fill="#fcfcfc">10</text>
    </svg>
  `,
  `
    <svg width="35" height="30" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7315 8.9365C12.026 7.1898 11.7719 4.85211 11.1756 3.33221C10.5995 1.86341 9.35764 0.908804 7.44074 0.693204C5.37514 0.460804 3.64814 1.0181 2.36824 2.0011C1.09144 2.9819 0.955045 4.4814 0.649445 6.5375C0.336545 8.6429 0.132037 10.2712 1.27564 11.8068C3.08164 14.2317 6.79494 16.0678 9.62644 13.1009C10.7918 11.8797 11.417 10.8012 11.7315 8.9365Z" fill="#4B4B4D"/>
    <text stroke="null" transform="matrix(0.22617145011998027,0,0,0.20729330973466592,3.640187237166999,7.654207915475816) " xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="22" id="svg_3" y="6.33581" x="-0.22881" stroke-opacity="null" stroke-width="0" fill="#fcfcfc">11</text>
    </svg>
  `,
  `
    <svg width="35" height="30" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.71214 8.25271C1.05644 9.44451 2.42764 10.7133 3.32734 11.2932C4.43744 12.0089 5.55694 13.2214 7.27604 12.7829C11.0068 11.8312 11.5308 8.94521 10.8037 5.56601C10.0834 2.21851 6.90514 -0.0434977 4.53194 0.827102C1.57604 1.9113 0.0264395 5.87881 0.71214 8.25271Z" fill="#4B4B4D"/>
    <text stroke="null" transform="matrix(0.22617145011998027,0,0,0.20729330973466592,3.640187237166999,7.654207915475816) " xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="22" id="svg_3" y="2.33581" x="-1.22881" stroke-opacity="null" stroke-width="0" fill="#fcfcfc">12</text>
    </svg>
  `,
  `
    <svg width="35" height="30" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.19414 3.1679C8.85574 2.9314 8.77394 2.648 8.53854 2.3602C8.34634 2.1252 8.04063 1.8425 7.83533 1.6396C7.28013 1.0904 6.55494 1.005 6.07904 0.623398C4.66434 0.808798 4.57214 0.352702 2.81434 1.6757C2.22084 2.1224 1.28895 3.2203 0.861446 4.1099C0.388647 5.094 0.335137 6.6863 0.676837 7.8119C1.18494 9.4856 4.68074 11.2362 6.07684 11.1137C7.07704 11.0259 8.47075 10.0497 8.98315 9.5992C10.4499 8.3098 10.5328 6.9205 9.81354 4.7051C9.63294 4.1487 9.27024 3.6553 9.19414 3.1679Z" fill="#4B4B4D"/>
    <text stroke="null" transform="matrix(0.22617145011998027,0,0,0.20729330973466592,3.640187237166999,7.654207915475816) " xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="22" id="svg_3" y="-0.33581" x="-3.22881" stroke-opacity="null" stroke-width="0" fill="#fcfcfc">13</text>
    </svg>  
  `,
  `
    <svg width="35" height="30" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.57775 2.0764C2.52115 2.3188 2.61935 2.056 2.49085 2.276L2.21205 2.641C2.07645 2.8211 1.97015 2.9852 1.85755 3.1667C0.978053 4.5832 0.106859 7.7088 1.20316 8.8725C2.05296 9.7746 3.15555 10.6931 4.40515 11.0445C5.99755 11.4922 8.67515 9.8182 9.42165 9.0846C10.524 8.0013 10.735 6.3424 10.2828 4.7214C10.1511 4.2493 9.86625 3.9034 9.79955 3.5073C9.77695 3.488 9.74795 3.4613 9.73365 3.4473L9.60865 3.3266C9.19175 2.8885 8.93005 2.37 8.45085 1.9535C5.26155 -0.818096 3.46475 1.6006 2.57775 2.0764Z" fill="#4B4B4D"/>
    <text stroke="null" transform="matrix(0.22617145011998027,0,0,0.20729330973466592,3.640187237166999,7.654207915475816) " xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="22" id="svg_3" y="-0.33581" x="-4.22881" stroke-opacity="null" stroke-width="0" fill="#fcfcfc">14</text>
    </svg>
  `,
  `
    <svg width="35" height="30" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.106149 5.96259C0.327149 7.70759 2.02595 11.5941 4.91345 11.7188C6.90745 11.8049 8.01235 11.5604 8.81865 10.3027C9.41835 9.3674 10.1553 7.82219 10.3835 6.60129C10.6471 5.19129 10.456 3.17159 9.64975 2.32659C8.88265 1.52289 7.16985 0.918396 5.68265 0.976896C3.75185 1.0528 -0.305651 2.71109 0.106149 5.96259Z" fill="#4B4B4D"/>
    <text stroke="null" transform="matrix(0.22617145011998027,0,0,0.20729330973466592,3.640187237166999,7.654207915475816) " xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="22" id="svg_3" y="-0.33581" x="-3.22881" stroke-opacity="null" stroke-width="0" fill="#fcfcfc">30</text>
    </svg>
  `,
  `
    <svg width="35" height="30" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4892 5.3045C10.6721 3.7072 10.3033 1.9391 9.35944 1.2405C8.45144 0.568597 6.67214 0.0200001 5.25814 0.2133C3.14594 0.502 -0.645656 2.3587 0.222644 5.5578C0.940044 8.2007 2.30445 10.9815 5.51665 10.9164C7.60125 10.8742 8.28984 10.6001 9.04734 9.1638C9.65414 8.013 10.3201 6.7821 10.4892 5.3045Z" fill="#4B4B4D"/>
    <text stroke="null" transform="matrix(0.22617145011998027,0,0,0.20729330973466592,3.640187237166999,7.654207915475816) " xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="22" id="svg_3" y="-3.33581" x="-3.22881" stroke-opacity="null" stroke-width="0" fill="#fcfcfc">29</text>
    </svg>
  `,
  `
    <svg width="35" height="30" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7587 5.9626C11.9834 4.0078 11.0255 2.65901 10.0773 1.77871C6.32445 -1.70549 1.24835 2.52331 0.809551 4.82621C0.318951 7.40131 1.59735 9.03801 3.27905 11.2127C3.93215 12.0573 5.37675 13.4565 7.09195 13.2888C10.6516 12.9407 11.4275 8.8439 11.7587 5.9626Z" fill="#4B4B4D"/>
    <text stroke="null" transform="matrix(0.22617145011998027,0,0,0.20729330973466592,3.640187237166999,7.654207915475816) " xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="22" id="svg_3" y="2.33581" x="0.22881" stroke-opacity="null" stroke-width="0" fill="#fcfcfc">28</text>
    </svg>
  `,
  `
    <svg width="35" height="30" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.502045 6.4013C0.846145 10.0301 1.57284 12.7231 5.14364 13.2053C6.85084 13.4358 8.83004 13.3384 9.52164 12.0863C10.6369 10.0673 10.4034 4.03291 9.26754 2.17751C8.77004 1.36501 7.20644 -0.0879954 5.59225 0.129205C4.37995 0.292205 2.67364 1.66251 1.98064 2.24161C1.39314 2.73261 1.06814 2.89371 0.728642 3.84121C0.440242 4.64561 0.417845 5.5138 0.502045 6.4013Z" fill="#4B4B4D"/>
    <text stroke="null" transform="matrix(0.22617145011998027,0,0,0.20729330973466592,3.640187237166999,7.654207915475816) " xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="22" id="svg_3" y="2.33581" x="-3.22881" stroke-opacity="null" stroke-width="0" fill="#fcfcfc">27</text>
    </svg>
  `,
  `
    <svg width="35" height="30" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.955444 3.22061C1.09244 5.95291 1.23505 9.6051 2.84115 11.1757C5.24655 13.528 7.69954 11.5636 8.54684 9.0858C9.06904 7.5586 9.18054 3.99851 8.93354 2.23561C8.65694 0.262307 4.78884 0.422899 3.33794 0.702599C1.03624 1.1461 0.878544 1.68591 0.955444 3.22061Z" fill="#4B4B4D"/>
    <text stroke="null" transform="matrix(0.22617145011998027,0,0,0.20729330973466592,3.640187237166999,7.654207915475816) " xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="22" id="svg_3" y="-0.33581" x="-5.22881" stroke-opacity="null" stroke-width="0" fill="#fcfcfc">26</text>
    </svg>
  `,
  `
    <svg width="35" height="30" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.730144 5.1948C0.781644 6.6919 0.765244 8.0772 1.26184 9.4185C2.87444 13.7733 7.10305 13.6187 7.83375 7.3359C8.00415 5.8715 8.01354 4.2026 7.93524 2.7158C7.83264 0.7669 7.56675 0.460398 5.48585 0.388398C-0.0968527 0.195398 0.593844 1.2287 0.730144 5.1948Z" fill="#4B4B4D"/>
    <text stroke="null" transform="matrix(0.22617145011998027,0,0,0.20729330973466592,3.640187237166999,7.654207915475816) " xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="22" id="svg_3" y="-0.33581" x="-10.22881" stroke-opacity="null" stroke-width="0" fill="#fcfcfc">25</text>
    </svg>
  `,
  `
    <svg width="35" height="30" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.57554 0.378206C2.37654 0.378206 0.954544 0.429609 0.696344 1.42311C0.184844 3.39141 0.213343 13.6576 4.73014 12.2727C7.80044 11.3313 7.79564 6.23491 7.79564 3.76901C7.79564 2.17021 8.05004 1.1451 6.85004 0.708001C5.86754 0.350201 4.65624 0.378206 3.57554 0.378206Z" fill="#4B4B4D"/>
    <text stroke="null" transform="matrix(0.22617145011998027,0,0,0.20729330973466592,3.640187237166999,7.654207915475816) " xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="22" id="svg_3" y="-0.33581" x="-10.22881" stroke-opacity="null" stroke-width="0" fill="#fcfcfc">24</text>
    </svg>
  `,
  ` 
  <svg width="35" height="30" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.51345 3.6593C8.66355 1.6368 8.47185 1.2788 6.62045 0.802897C5.21225 0.440797 3.54585 0.454995 2.14735 0.836695C0.332352 1.3321 0.482946 2.18049 0.460946 4.09799C0.430246 6.77379 0.483944 10.0584 2.77684 11.6648C3.98344 12.5101 5.44105 12.3749 6.39445 11.4455C6.86415 10.9875 7.16265 10.6599 7.47845 10.0073C7.74115 9.4646 7.96465 8.879 8.06735 8.1861C8.27385 6.7945 8.40745 5.088 8.51345 3.6593Z" fill="#4B4B4D"/>
    <text stroke="null" transform="matrix(0.22617145011998027,0,0,0.20729330973466592,3.640187237166999,7.654207915475816) " xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="22" id="svg_3" y="-0.33581" x="-8.22881" stroke-opacity="null" stroke-width="0" fill="#fcfcfc">23</text>
  </svg>
  `,
  `
    <svg width="35" height="30" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.98455 6.5109C10.1837 4.3655 9.83865 3.3126 8.59355 2.2913C7.96385 1.7748 6.12485 0.334712 5.02245 0.142212C3.35195 -0.149388 1.84205 1.2848 1.27765 2.0988C0.0943478 3.8055 -0.136751 9.9356 0.948849 12.0167C1.62275 13.3084 3.46215 13.4406 5.27105 13.2163C8.85585 12.7719 9.64815 10.136 9.98455 6.5109Z" fill="#4B4B4D"/>
    <text stroke="null" transform="matrix(0.22617145011998027,0,0,0.20729330973466592,3.640187237166999,7.654207915475816) " xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="22" id="svg_3" y="2.33581" x="-5.22881" stroke-opacity="null" stroke-width="0" fill="#fcfcfc">22</text>
    </svg>
  `,
  `
    <svg width="35" height="30" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.736846 5.96259C0.845246 7.02509 1.49355 9.97469 1.90225 10.7863C2.81655 12.6019 5.38075 14.2365 7.70675 12.6451C9.12115 11.6774 11.1696 8.6718 11.5429 7.5923C11.8116 6.8151 11.8454 5.6864 11.687 4.8319C11.4798 3.714 10.634 2.81879 9.81875 2.21169C9.50705 1.97949 9.35064 1.85009 8.99774 1.60659C8.42114 1.20879 7.78585 0.835896 7.04185 0.604796C4.04215 -0.327104 0.370346 2.36859 0.736846 5.96259Z" fill="#4B4B4D"/>
    <text stroke="null" transform="matrix(0.22617145011998027,0,0,0.20729330973466592,3.640187237166999,7.654207915475816) " xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="22" id="svg_3" y="2.33581" x="-0.22881" stroke-opacity="null" stroke-width="0" fill="#fcfcfc">21</text>
    </svg>
  `,
  `
    <svg width="35" height="30" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.00834 5.30451C1.15484 6.70851 1.85953 8.08431 2.45843 9.17151C3.26193 10.6302 3.95504 10.8767 5.99734 10.9157C7.92254 10.9525 8.80934 10.1624 9.55694 9.19161C10.2887 8.24131 10.8787 6.95981 11.2579 5.63061C11.7677 3.84351 10.8875 2.56041 9.90404 1.83581C7.58404 0.126706 4.71684 -0.549597 2.15464 1.2401C1.03104 2.025 0.877337 4.05001 1.00834 5.30451Z" fill="#4B4B4D"/>
    <text stroke="null" transform="matrix(0.22617145011998027,0,0,0.20729330973466592,3.640187237166999,7.654207915475816) " xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="22" id="svg_3" y="-2.33581" x="-0.22881" stroke-opacity="null" stroke-width="0" fill="#fcfcfc">20</text>
    </svg>
  `,
  `
    <svg width="35" height="30" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8074 5.2916C11.0122 3.8457 10.7412 1.8588 9.76944 1.1326C8.89494 0.4791 7.04145 -0.110292 5.62755 0.0602077C4.19025 0.233508 2.95095 0.963902 1.97505 1.6567C1.02845 2.3286 0.104537 3.56441 0.535437 5.32611C0.821737 6.49651 1.63805 8.22941 2.22415 9.00681C2.95215 9.97251 3.94645 10.7981 5.71755 10.7703C7.74755 10.7385 8.54785 10.5239 9.32755 9.12021C9.93065 8.03451 10.6046 6.7237 10.8074 5.2916Z" fill="#4B4B4D"/>
    <text stroke="null" transform="matrix(0.22617145011998027,0,0,0.20729330973466592,3.640187237166999,7.654207915475816) " xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="22" id="svg_3" y="-2.33581" x="-3.22881" stroke-opacity="null" stroke-width="0" fill="#fcfcfc">19</text>
    </svg>
  `,
];

export const arrow = (color) => `
  <svg width="15px" height="15px" viewBox="0 0 400 400" xmlns="http://www.w3.org/2000/svg">
    <polygon 
      points="250,60 100,400 400,400"
      fill="${color}"
    />
  </svg>
`;

export const reversedArrow = (color) => `
  <svg width="15px" height="15px" viewBox="0 0 400 400" xmlns="http://www.w3.org/2000/svg">
    <polygon transform="rotate(179.87063598632812 210.99999999999997,207) " id="svg_1" fill="${color}" points="211,37 61,377 361,377 "/>
  </svg>
`;