import React, { useState } from 'react';
import PaperPlane from '../../UI/PaperPlane';
import './index.scss'

function MessageMe({ changeView, colorChange }) {
  const [chatOn, setChatOn] = useState(false)
  const clickHandler = () => {
    setChatOn(!chatOn);
    changeView('chatApp')
  };

  return (
    <button
      style={{
        'backgroundColor': colorChange(process.env.REACT_APP_THEME),
      }}
      onClick={clickHandler}
      className='message_me'
    >
      <div className="message_me__icon">
        <PaperPlane />
      </div>
    </button>
  )
}

export default MessageMe;
