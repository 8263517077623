import React, { useState } from 'react';
import { userLogin, showNotification, Notification } from 'react-admin';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import logoHealth from '../../img/logo.svg';
import logoSTS from '../../img/logo.png';
import logoSTE from '../../img/logoSte.png';
import logoReb from '../../img/logoRebBlue.png';
import styles from './styles';

const logoSwitcher = {
  sts: logoSTS,
  ste: logoSTE,
  health: logoHealth,
  reb: logoReb,
}

const logo = logoSwitcher[process.env.REACT_APP_THEME];

export default connect(undefined, { userLogin, showNotification })(withStyles(styles)(props => {
  const { classes, userLogin } = props;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const SignIn = _ => userLogin({ username, password });

  return (
    <div className={classes.LogPage}>
      <div className={classes.LeftSide}>
        <img src={logo} alt="test" className={classes.Logo} />
      </div>
      <div className={classes.RightSide}>
        <div className={classes.FormWrapper}>
          <div className={classes.Text}>
            Welcome back,<br />
            please login to continue
          </div>
          <div className={classes.Form}>
            <TextField
              id="username"
              label="Username"
              value={username}
              onChange={e => setUsername(e.target.value)}
              margin="normal"
              className={classes.TextInput}
              variant="outlined"
            />
            <TextField
              id="password"
              label="Password"
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              margin="normal"
              variant="outlined"
              className={classes.TextInput}
            />
            <Button
              variant="contained"
              color='primary'
              onClick={SignIn}
              className={classes.Button}
            >
              Sign In
            </Button>
            <Notification />
          </div>
        </div>
      </div>
    </div>
  );
}));


